#PPS_Tabs .e-tab .e-tab-header {
    background: inherit;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    font-weight: normal;
    border-bottom: 0;
    margin-left: 20px;
}

.fixedBottomPane {
    position: fixed;
    bottom: 0;
    left: 20px;
    right: 0;
    background-color: #bfd5e9;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-top: 1px solid #000;
    grid-gap: 1px;
    gap: 1px;
}