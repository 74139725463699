.Machine_info_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 70vh;
  max-width: 94vw;
  gap: 10px;
  box-sizing: border-box;
  background-color: #bfd5e9;
  padding: 10px;
}

.first_quadrant {
  border: 1.5px solid black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 18vh;
}

.second_quadrant {
  border: 1.5px solid black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.mold_quadrant {
  display: flex;
  justify-content: flex-end;
  height: 35vh;
  padding: 5px;
  overflow-y: auto
}

.nozzleSettingsContainer {
  display: flex;
  justify-content: flex-end;
}

.nozzleSettings {
  border: 1.5px solid black;
  padding: 12px;
  display: flex;
  gap: 30px;
  background-color: #bfd5e9;
  box-sizing: border-box;
  width: 100%;
}

.existingNozzleSettings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: flex-end;
}

.nozzleSettingsBox {
  border: 1.5px solid black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  height: auto;
  background-color: #bfd5e9;
  box-sizing: border-box;
}

.nozzleSettingsHeader {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
}

.nozzleSettingsContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nozzleSettingsItem {
  display: flex;
  align-items: center;
}

.nozzleSettingsLabel {
  width: 140px;
  margin-right: 10px;
}

.smallTextBox {
  flex: 1;
  width: 120px;
}

.formGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  color: #000;
  margin-right: 5px;
}

.textBox {
  width: 100px;
  height: 20px;
}

.textBoxWide {
  width: 280px;
  height: 20px;
}

.rtfBoxWide {
  width: 500px;
  height: 50px;
  resize: none; 
}

.labelDate {
  margin-left: 0.2em;
}

.selectBox {
  width: 130px;
  height: 25px;
}

.datePicker {
  width: 140px;
  height: 20px;
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}