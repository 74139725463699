#main-dashboard .card {
    /* border: 1px solid rgb(42, 152, 164);
    border-radius: 0; */
}
/* #main-dashboard{
    background-image: url("");
} */

#main-dashboard .card{
    box-shadow: 3px 3px 15px rgb(110, 111, 111,0.5);
}

#main-dashboard .card:hover {
    box-shadow: 5px 5px 5px #9ac2e0;
    cursor: pointer;
    border: 0;
}

.icon-container {
    font-size: 2em;
    /* margin-bottom: 10px; */
    color: #333;
}
.card .card-footer, .card .card-header{
    padding: 0 0px 15px 10px;
}
.card .card-body{
    padding: 10px;
}