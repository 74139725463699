.splitter {
    display: flex;
    width: 100%;
    height: auto;
  }
  
  .pane {
    /*display: flex;
    justify-content: start;
     align-items: center; */
    overflow: auto;
  }
  
  .resizer {
    width: 5px;
    height: auto;
    cursor: ew-resize;
    background-color: #e3165b;
  }
  
  .content {
    padding: 10px;
  }
  
  .h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .code-preview {
    background: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    font-family: monospace;
  }
  