/**************************************** CSS for all the grid containers *****************************************/
.MoldTempGrid {
    border: 1px solid black;
    margin: 15px;
    width: 100%;
    max-width: 25vw;
}

.BarrelSettingsGrid {
    border: 1px solid black;
    width: 45vw;
    margin: 15px 1px 5px 1px;
}

.PositionGrid {
    border: 1px solid black;
    width: 53vw;
    margin: 5px 1px 5px 1px;
}

.DecompressionGrid {
    border: 1px solid black;
    width: 15vw;
    margin: 15px 1px 5px 10px;
}

.PackHoldGrid {
    border: 1px solid black;
    width: 37vw;
    margin: 0px 1px 5px 15px;
}

.InjectionPhaseGrid {
    border: 1px solid black;
    width: 36vw;
    margin: 0px 1px 5px 30px;
}

.ScrewRotGrid {
    border: 1px solid black;
    width: 8vw;
    margin: 40px 1px 5px 0px;
}

/* ******************************************************************** */
#PPSGrid {
    font-size: 1em;
    color: black;
}

#PPSGrid th {
    text-align: center;
    width: 10vw;
}

#PPSGrid td {
    padding: 0px;
    border: 1px solid black;
    text-align: center;
}

#PPSGrid .form-control {
    background-color: rgba(250, 240, 225, 255);
    border-radius: 0;
}

#PPSGrid p {
    margin: 0;
    font-size: 1em;
    color: black;
}

.InputsGrid td {
    width: 10vw;
}

.Screw_Section {
    position: absolute;
    z-index: 10;
    top: 130px;
    left: 425px;
}

.brackets-section {
    margin-left: 34em;
    margin-top: 0.8em
}

.TabCardTitle {
    border: 1px solid black;
    margin-bottom: 5px;
    text-align: center;
    color: white;
    background-color: #4682b4;
}