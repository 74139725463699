/* @import '../node_modules/@syncfusion/ej2-base/styles/material.css'; */
/* @import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css'; */

.Chart-div {
    /* background-color: #E6FFFE; */
    background-color: #dfebea;
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 90px;
}



/* #chartsAxisLabels2 {
    visibility:hidden;
} */

#chart1AxisLabels2 {
    visibility: hidden;
}

#chart2AxisLabels2 {
    visibility: hidden;
}


#charts_stripline_Over_text_yAxis1_0{
    fill: rgb(24, 23, 23);
    /* transform: matrix(1, 0, 0, 1, 670, 0); */
}

#charts_stripline_Over_text_yAxis1_1{
    fill: rgb(24, 23, 23);
    
}

#charts_stripline_Over_text_yAxis1_2{
    fill: rgb(24, 23, 23);
    /* transform: matrix(1, 0, 0, 1, 670, 0); */
}

.btn-container {
    height: 50px;
    position: relative;
    /* border: 3px solid green;  */
}

.btn-div {
    margin: 0;
    position: absolute;
    background-color: rgb(245, 242, 242);
    height: 20px;
    padding-top: 0;
    justify-content: center;
}


.btn-div button {
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    width: 40px;
    box-shadow: none;
    border: none;
    font-weight: bold;
    background-color: rgb(192, 190, 190);
}

#chart_57723_1AxisLabels0 {
    visibility: hidden;
}